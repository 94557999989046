<template>
  <app-menu-form
    :headers="timesheetByPositionHeaders"
    :serverItems="timesheetByPositions"
    :loading="loading"
    :serverItemsLength="timesheetByPositionsCount"
    hideEdit
    hideDelete
    :isSelecteable="false"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top title="Timesheet By Position"></app-menu-form-top>
    </template>

    <template v-slot:foot>
      <tfoot>
        <tr>
          <td></td>
          <td>
            {{ hoursMinutesFormat(timesheetByPositionSumStandardManDay) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByPositionSumAssignedManDay) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByPositionSumUnassigned) }}
          </td>
          <td>
            {{ percentFormat(timesheetByPositionSumAssignedProgress) }}
          </td>
          <td>
            {{
              hoursMinutesFormat(timesheetByPositionSumFinishedAssignedManDay)
            }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByPositionSumActualManDay) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByPositionSumVariance) }}
          </td>
          <td>
            {{ percentFormat(timesheetByPositionSumTaskPerformance) }}
          </td>
          <td>
            {{ percentFormat(timesheetByPositionSumWorkProgress) }}
          </td>
          <td>
            {{ percentFormat(timesheetByPositionSumWeight) }}
          </td>
        </tr>
      </tfoot>
    </template>
  </app-menu-form>
</template>

<script>
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'
import { hoursMinutes, percentFormat } from '@utils/number-format'

export default {
  name: 'app-timesheet-position',
  components: {
    AppMenuForm,
    AppMenuFormTop
  },
  props: ['auditPlanId', 'auditId', 'taskId'],
  data() {
    return {
      timesheetByPositions: [],
      timesheetByPositionsCount: 0,
      timesheetByPositionSumStandardManDay: 0,
      timesheetByPositionSumAssignedManDay: 0,
      timesheetByPositionSumUnassigned: 0,
      timesheetByPositionSumAssignedProgress: 0,
      timesheetByPositionSumFinishedAssignedManDay: 0,
      timesheetByPositionSumActualManDay: 0,
      timesheetByPositionSumVariance: 0,
      timesheetByPositionSumTaskPerformance: 0,
      timesheetByPositionSumWorkProgress: 0,
      timesheetByPositionSumWeight: 0,
      loading: false
    }
  },
  computed: {
    timesheetByPositionHeaders() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'task_sequence',
          hideFilter: false,
          filterMode: 'exact',
          filterName: 'task__sequence'
        },
        {
          text: this.$t('fields.task'),
          value: 'task_name',
          hideFilter: false,
          filterName: 'task__name'
        },
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'position__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.unassigned'),
          value: 'unassigned',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedProgress'),
          value: 'assigned_progress',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'weight',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getTimesheetByPositions
      }
    }
  },
  methods: {
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    async getTimesheetByPositions(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.task_id = this.taskId
        params.audit_id = this.auditId
        params.audit_plan_id = this.auditPlanId
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-position/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByPositions = data.results
        this.timesheetByPositionsCount = data.count

        this.timesheetByPositionSumStandardManDay = data.sum_standard_man_day
        this.timesheetByPositionSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByPositionSumUnassigned = data.sum_unassigned
        this.timesheetByPositionSumAssignedProgress = data.sum_assigned_progress
        this.timesheetByPositionSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByPositionSumActualManDay = data.sum_actual_man_day
        this.timesheetByPositionSumVariance = data.sum_variance
        this.timesheetByPositionSumTaskPerformance = data.sum_task_performance
        this.timesheetByPositionSumWorkProgress = data.sum_work_progress
        this.timesheetByPositionSumWeight = data.sum_weight
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
