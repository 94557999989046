<template>
  <app-menu-form
    :headers="subtaskHeaders"
    :server-items="subtasks"
    :server-items-length="subtasksCount"
    :loading="loading"
    :events="eventHandlers"
    :is-expand-server="true"
    :is-expand-server-next="true"
    :single-expand="true"
    :expand-items="grandChildSubtasks"
    :expand-items-length="grandChildSubtasksLength"
    sub-item-key="id"
    item-key="id"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Sub Task"
        :mode="mode"
        :dialog="dialogNewTask"
        @show-dialog="dialogNewTask = $event"
        dialog-max-width="300px"
        @onClicked="onNewTaskClick"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="app-input--approve-wrapper">
                    <app-input
                      v-model="newTaskTemplate"
                      name="taskTemplate"
                      type="select-server"
                      :label="$t('fields.taskTemplate')"
                      :view="view"
                      :binds="{
                        apiUrl: 'task/task-template-header/?active=true'
                      }"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCreateNewTaskClick">
                {{ $t('btn.create') }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onCancelNewTaskClick">
                {{ $t('btn.cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveNewTaskClick">
                {{ $t('btn.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'app-sub-task',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogNewTask: false,
      newTaskTemplate: null,
      loading: false,
      subtasks: [],
      subtasksCount: 0,
      grandChildSubtasks: {},
      grandChildSubtasksLength: {}
    }
  },
  computed: {
    subtaskHeaders() {
      return [
        {
          text: this.$t('fields.auditPhase'),
          value: 'audit_phase',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.prepareBy'),
          value: 'prepare_by.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.reviewBy'),
          value: 'review_by.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getSubtasks,
        edit: this.onEditTask,
        view: this.onEditTask,
        delete: this.onDeleteTask,
        'item-expanded': this.getGrandChildSubtasks,
        'expand-view': this.onEditTask
      }
    }
  },
  methods: {
    async getSubtasks(options = null) {
      this.loading = true
      try {
        options.sortBy = ['sequence', 'reference']
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/subtask/?parent_task_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.subtasks = data.results
        this.subtasksCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.$route.query.audit_plan_id,
          audit_id: this.$route.query.audit_id,
          from: this.$route.query.from
        }
      })
    },
    onDeleteTask(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        })
          .then(() => {
            this.$refs.subtaskTable.onServer()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async getGrandChildSubtasks(item, value) {
      if (!value) return
      this.loading = true
      try {
        const options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['sequence', 'reference']
        }
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/subtask/?parent_task_id=${item.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.grandChildSubtasks[item.id] = data.results
        this.grandChildSubtasksLength[item.id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onNewTaskClick() {
      this.dialogNewTask = true
    },
    onCancelNewTaskClick() {
      this.newTaskTemplate = null
      this.dialogNewTask = false
    },
    onCreateNewTaskClick() {
      this.dialogNewTask = false
      this.$router.push({
        name: 'taskCreate',
        query: {
          from: this.$route.query.from,
          audit_plan_id: this.$route.query.audit_plan_id,
          audit_id: this.$route.query.audit_id,
          parent_task_id: this.$route.params.id
        }
      })
    },
    onSaveNewTaskClick() {
      this.dialogNewTask = false
      if (this.newTaskTemplate) {
        this.$router.push({
          name: 'taskCreate',
          query: {
            from: this.$route.query.from,
            audit_plan_id: this.$route.query.audit_plan_id,
            audit_id: this.$route.query.audit_id,
            parent_task_id: this.$route.params.id,
            task_template_header_id: this.newTaskTemplate.id
          }
        })
      }
    }
  }
}
</script>
