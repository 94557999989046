<script setup>
import { onTimeHrsKeyDow, timeHrsAutoConvert } from '@utils/time'
import AppInput from '@components/AppInput.vue'
import { ref, watch } from 'vue'
const props = defineProps({
  value: {
    type: [String, Number],
    default: null
  },
  view: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['input'])
const internalValue = ref(timeHrsAutoConvert(props.value))
watch(
  () => props.value,
  (value) => {
    internalValue.value = timeHrsAutoConvert(value)
  }
)
const onBlur = () => {
  internalValue.value = timeHrsAutoConvert(internalValue.value)
  emit('input', timeHrsAutoConvert(internalValue.value))
}
const onUpdateValue = (value) => {
  internalValue.value = value
}
</script>

<template>
  <app-input
    :name="props.name"
    :label="props.label"
    :view="props.view"
    :handleBlur="onBlur"
    :handleKeyDown="onTimeHrsKeyDow"
    :value="internalValue"
    @input="onUpdateValue"
  />
</template>

<style scoped lang="scss"></style>
