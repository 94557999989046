var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('app-table',{attrs:{"server-side":"","app":"audit","model":"taskactivityprofile","headers":_vm.taskActivityProfileHeader,"hide-edit":_vm.mode !== 'edit',"hide-delete":_vm.mode !== 'edit',"serverItems":_vm.taskActivityProfileItems,"clientItems":_vm.taskActivityProfileItems,"loading":_vm.loading,"server-items-length":_vm.taskActivityProfileItemsLength},on:{"server":_vm.getTaskActivityProfileItems,"client":_vm.getTaskActivityProfileClientItems,"delete":_vm.onDeleteTaskActivityProfileItem,"edit":_vm.onEditTaskActivityProfile},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"app-list-view"},[_c('div',{staticClass:"app-list-view--header"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('menu.activityProfile'))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("actions",function(){return [_vm._t("prefix-actions"),_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.mode === 'edit'),expression:"mode === 'edit'"}],attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Item ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.taskActivityProfileSelected.length && _vm.mode === 'edit'
                      ),expression:"\n                        taskActivityProfileSelected.length && mode === 'edit'\n                      "}],attrs:{"color":"error"},on:{"click":function($event){return _vm.onDeleteTaskActivityProfile(
                          _vm.taskActivityProfileSelected
                        )}}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")]),_vm._t("suffix-actions")]})]}}],null,true),model:{value:(_vm.dialogTaskActivityProfile),callback:function ($$v) {_vm.dialogTaskActivityProfile=$$v},expression:"dialogTaskActivityProfile"}},[(_vm.dialogTaskActivityProfile)?_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"documentTemplate","rules":"required","type":"select-server","label":_vm.$t('fields.activityProfile'),"view":_vm.view,"binds":{
                              apiUrl:
                                'activity/activity-profile-header/?active=true'
                            }},model:{value:(_vm.editedItem.activity_profile_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "activity_profile_id", $$v)},expression:"editedItem.activity_profile_id"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeTaskActivityProfile}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveTaskActivityProfile}},[_vm._v(" Save ")])],1)],1):_vm._e()],1)],1)],1)])]},proxy:true}]),model:{value:(_vm.taskActivityProfileSelected),callback:function ($$v) {_vm.taskActivityProfileSelected=$$v},expression:"taskActivityProfileSelected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }