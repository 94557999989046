<template>
  <v-card flat>
    <v-card-text>
      <app-table
        server-side
        :app="app"
        :model="model"
        :headers="headers"
        :server-items="serverItems"
        :client-items="clientItems"
        :loading="loading"
        :server-items-length="serverItemsLength"
        :is-selecteable="isSelectable"
        :hide-edit="hideEdit"
        :hide-delete="hideDelete"
        v-model="selected"
        v-on="events"
        :is-expand-server="isExpandServer"
        :single-expand="singleExpand"
        :expand-server-headers="expandServerHeaders"
        :expand-items="expandItems"
        :expand-items-length="expandItemsLength"
        :sub-item-key="subItemKey"
        :item-key="itemKey"
      >
        <template v-slot:[`item.chart_of_account_details`]="{ value }">
          <slot :name="[`item.chart_of_account_details`]" :value="value"></slot>
        </template>

        <template v-slot:[`item.personal_document_id`]="{ item }">
          <slot :name="[`item.personal_document_id`]" :item="item"></slot>
        </template>

        <template v-slot:[`item.group_link`]="{ item }">
          <slot :name="[`item.group_link`]" :item="item"></slot>
        </template>

        <template v-slot:top>
          <slot name="top" :selected="selected"></slot>
        </template>

        <template v-slot:foot>
          <slot name="foot"></slot>
        </template>

        <template v-slot:expandServerTable="{ item }">
          <slot name="expandServerTable" v-bind:item="item"></slot>
        </template>
      </app-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppTable from '@components/AppTable.vue'

export default {
  name: 'app-menu-form',
  components: {
    AppTable
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    serverItems: {
      type: Array,
      required: true
    },
    clientItems: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    hideEdit: {
      type: Boolean
    },
    hideDelete: {
      type: Boolean
    },
    events: {
      type: Object
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isExpandServer: {
      type: Boolean,
      default: false
    },
    expandServerHeaders: {
      type: Array,
      default: () => []
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    expandItems: {
      type: Object
    },
    expandItemsLength: {
      type: Object
    },
    subItemKey: {
      type: String
    },
    itemKey: {
      type: String
    },
    app: {
      type: String,
      default: 'audit'
    },
    model: {
      type: String,
      default: 'task'
    }
  },
  data() {
    return {
      selected: []
    }
  }
}
</script>
