<template>
  <app-menu-form
    :headers="relatedCOAItemHeader"
    :server-items="relatedCOAItemItems"
    :client-items="relatedCOAItemItems"
    :loading="loading"
    :server-items-length="relatedCOAItemServerItemsLength"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Related COA"
        :mode="mode"
        :dialog="dialogRelatedCOAItem"
        :selected-length="relatedCOASelected.length"
        :on-delete="onDeleteRelatedCOAItem"
        @show-dialog="dialogRelatedCOAItem = $event"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <app-input
                      name="chart_of_account"
                      type="select-server"
                      :label="$t('fields.chartOfAccount')"
                      :view="view"
                      :binds="{
                        apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                        itemText: (item) => `${item.code} ${item.name}`
                      }"
                      v-model="editedRelatedCOA.chart_of_account_detail_id"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRelatedCOAItem">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveRelatedCOAItem">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'app-related-coa',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    audit_header_id: Number
  },
  data() {
    return {
      relatedCOASelected: [],
      editedRelatedCOA: {},
      relatedCOAItemItems: [],
      relatedCOAItemServerItemsLength: 0,
      dialogRelatedCOAItem: false,
      loading: false
    }
  },
  computed: {
    relatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_id.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_id.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.dr'),
          value: 'dr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.cr'),
          value: 'cr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getRelatedCOAItemItems,
        delete: this.onDeleteRelatedCOAItemItem,
        edit: this.onEditRelatedCOAItem
      }
    }
  },
  methods: {
    async getRelatedCOAItemItems(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${
            this.$route.query.audit_id
              ? this.$route.query.audit_id
              : this.audit_header_id
          }/task/${this.$route.params.id}/related-coa/`,
          hideSuccessAlert: true,
          params: params
        })
        this.relatedCOAItemItems = data.results
        this.relatedCOAItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteRelatedCOAItemItem(item) {
      this.onDeleteRelatedCOAItem(item)
    },
    onDeleteRelatedCOAItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-header/${
            this.$route.query.audit_id
              ? this.$route.query.audit_id
              : this.audit_header_id
          }/task/${this.$route.params.id}/related-coa/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.relatedCOASelected = []
          this.getRelatedCOAItemItems()
        })
      })
      this.loading = false
    },
    closeRelatedCOAItem() {
      this.dialogRelatedCOAItem = false
      this.$nextTick(() => {
        this.editedRelatedCOA = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    async saveRelatedCOAItem() {
      try {
        this.loading = true
        const data = {
          chart_of_account_detail_id:
            this.editedRelatedCOA.chart_of_account_detail_id.id
        }
        if (this.editedRelatedCOA.id) {
          await this.$api({
            method: 'put',
            url: `audit/audit-header/${
              this.$route.query.audit_id
                ? this.$route.query.audit_id
                : this.audit_header_id
            }/task/${this.$route.params.id}/related-coa/${
              this.editedRelatedCOA.id
            }/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/audit-header/${
              this.$route.query.audit_id
                ? this.$route.query.audit_id
                : this.audit_header_id
            }/task/${this.$route.params.id}/related-coa/`,
            data
          })
        }
        this.closeRelatedCOAItem()
        await this.getRelatedCOAItemItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedCOAItem(item) {
      this.editedRelatedCOA = Object.assign({}, item)
      this.dialogRelatedCOAItem = true
    }
  }
}
</script>
