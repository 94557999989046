<template>
  <div class="app-list-view">
    <div class="app-list-view--header">
      <v-toolbar flat>
        <div v-if="title">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </div>
        <v-spacer></v-spacer>

        <!-- New Item Dialog Trigger -->
        <v-dialog v-model="localDialog" :max-width="dialogMaxWidth">
          <template v-if="isActivator" v-slot:activator="{ on, attrs }">
            <slot name="actions">
              <slot name="prefix-actions" />
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                v-show="mode === 'edit'"
                @click="onClicked"
              >
                New Item
              </v-btn>
              <v-btn
                v-show="selectedLength && mode === 'edit'"
                color="error"
                @click="onDelete(requiredDocumentSelected)"
              >
                {{ $t('btn.delete') }}
              </v-btn>
              <slot name="suffix-actions" />
            </slot>
          </template>

          <slot
            name="dialogForm"
            v-if="localDialog || !destroyDialogOnClose"
          ></slot>
        </v-dialog>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-menu-form-top',
  props: {
    mode: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    onClicked: {
      type: Function
    },
    onDelete: {
      type: Function
    },
    dialog: {
      type: Boolean,
      default: false
    },
    selectedLength: {
      type: Number
    },
    dialogMaxWidth: {
      type: String,
      default: '500px'
    },
    isActivator: {
      type: Boolean,
      default: true
    },
    destroyDialogOnClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localDialog: this.dialog
    }
  },
  watch: {
    dialog(show) {
      this.localDialog = show
    },
    localDialog(show) {
      this.$emit('show-dialog', show)
    }
  }
}
</script>
