<template>
  <app-menu-form
    :headers="timesheetByMemberHeaders"
    :serverItems="timesheetByMembers"
    :loading="loading"
    :serverItemsLength="timesheetByMembersCount"
    hideDelete
    hideEdit
    :isSelecteable="false"
    :events="eventHandlers"
    :is-expand-server="true"
    :singleExpand="true"
    :expand-server-headers="timesheetByMemberIdHeaders"
    :expandItems="timesheetByMemberIdItems"
    :expandItemsLength="timesheetByMemberIdItemsCount"
    subItemKey="member_id"
    itemKey="member_id"
  >
    <template v-slot:foot>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td>
            {{ hoursMinutesFormat(timesheetByMemberSumAssignedManDay) }}
          </td>
          <td>
            {{ percentFormat(timesheetByMemberSumWeight) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByMemberSumFinishedAssignedManDay) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByMemberSumActualManDay) }}
          </td>
          <td>
            {{ hoursMinutesFormat(timesheetByMemberSumVariance) }}
          </td>
          <td>
            {{ percentFormat(timesheetByMemberSumTaskPerformance) }}
          </td>
          <td>
            {{ percentFormat(timesheetByMemberSumWorkProgress) }}
          </td>
        </tr>
      </tfoot>
    </template>
  </app-menu-form>
</template>

<script>
import AppMenuForm from '@components/AppMenuForm.vue'
import { defaultTableParams } from '@utils/app-table'
import { hoursMinutes, percentFormat } from '@utils/number-format'

export default {
  name: 'app-timesheet-member',
  components: {
    AppMenuForm
  },
  props: ['auditPlanId', 'auditId', 'taskId'],
  data() {
    return {
      timesheetByMembers: [],
      timesheetByMembersCount: 0,
      timesheetByMemberSumStandardManDay: 0,
      timesheetByMemberSumAssignedManDay: 0,
      timesheetByMemberSumWeight: 0,
      timesheetByMemberSumFinishedAssignedManDay: 0,
      timesheetByMemberSumActualManDay: 0,
      timesheetByMemberSumVariance: 0,
      timesheetByMemberSumTaskPerformance: 0,
      timesheetByMemberSumWorkProgress: 0,
      timesheetByMemberIdItems: {},
      timesheetByMemberIdItemsCount: {},
      loading: false
    }
  },
  computed: {
    timesheetByMemberHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: false,
          filterName: 'audit_plan_member__contact_id__name'
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetByMemberIdHeaders() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'task_sequence',
          hideFilter: false,
          filterMode: 'exact',
          filterName: 'task__sequence'
        },
        {
          text: this.$t('fields.task'),
          value: 'task_name',
          hideFilter: false,
          filterName: 'task__name'
        },
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'task_standard_man_day__position_id__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'total_standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getTimesheetByMembers,
        'item-expanded': this.getTimesheetByMemberIdItems
      }
    }
  },
  methods: {
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    async getTimesheetByMembers(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.task_id = this.taskId
        params.audit_id = this.auditId
        params.audit_plan_id = this.auditPlanId
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByMembers = data.results
        this.timesheetByMembersCount = data.count
        this.timesheetByMemberSumStandardManDay = data.sum_standard_man_day
        this.timesheetByMemberSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByMemberSumWeight = data.sum_weight
        this.timesheetByMemberSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByMemberSumActualManDay = data.sum_actual_man_day
        this.timesheetByMemberSumVariance = data.sum_variance
        this.timesheetByMemberSumTaskPerformance = data.sum_task_performance
        this.timesheetByMemberSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMemberIdItems(item, value, options) {
      if (!value || !options) return
      this.loading = true
      try {
        let params = defaultTableParams(options)

        params.task_id = this.taskId
        params.audit_id = this.auditId
        params.audit_plan_id = this.auditPlanId
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/${item.member_id}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.timesheetByMemberIdItems[item.member_id] = data.results
        this.timesheetByMemberIdItemsCount[item.member_id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
